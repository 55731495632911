.btn-filter {
  text-align: left;
  // color: $green-primary;
  height: 100%;
}

.icon-filter {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.color-2 {
  height: 44px;
  background-color: #f4f4f4;
  border: 1px solid rgba(20, 179, 184, .5);
  width: 187px;
  text-align: center;
}

.color-1 {
  height: 44px;
  background-color: white;
  color: #0d2536;
}

.offerts-header>.mat-sort-header-container {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #0d2536;
}

.offerts-header>.mat-sort-header-stem {
  color: #0d2536;
  height: 13px;
}

.offerts-header>.mat-sort-header-indicator {
  color: #0d2536;
}

.center-header>.mat-sort-header-container {
  display: flex;
  justify-content: center;
}

.cdk-global-overlay-wrapper {
  justify-content: center !important;
}

.cursor {
  cursor: pointer;
}

.invert-dialog-m>.mat-dialog-content {
  margin: 0px;
}

.buttons-invest>.mat-button[disabled][disabled] {
  background-color: grey;
  color: #0d2536;
}

.buttons-invest>.mat-button>.button-blue>.mat-button-wrapper {
  color: white;
  font-family: 'Montserrat';
  size: 17px !important;
  font-weight: 500 !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.7;
  z-index: 999999;
}

.title-done {
  color: #183245;
  font-size: 1.8rem;
}

.text-done {
  font-size: 14px;
  color: #003439;
}

.button-blue {
  width: 240px;
  height: 44px;
  background-color: #0E1E28;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 400;
  border-width: 0px;
}

.modalbox-survey-p>mat-dialog-container {
  padding: 0px;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.leadForm>.mat-form-field.mat-focused .mat-form-field-label {
  color: #19d7db !important;
}

/* underline border color on focused */
.leadForm>.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: #19d7db !important;
}

.leadForm>.mat-radio-label-content {
  padding-left: 4px !important;
}


/*Nueva integracion de mis inversiones*/

:root {
  --green-primary:  #00909B;
  --green-light: #19D7DB;
  --green-success: #00D284;
  --green-medium: #0EC480;
  --orange-primary: #FF5730;
  --red-medium: #F1512C;
  --blue-primary: #248AFD;
  --blue-light: #00CFF4;
  --blue-medium: #09CBEE;
  --purple-primary: #7859DF;
  --yellow-primary: #FEDF1B;
  --yellow-medium: #EEDF0C;
  --yellow-warning: #FFD603;
  --gray-default: #274156;
  --green-dark: #183245
}
@mixin customActionElementActiveCard($background, $textColor) {

  input[type="checkbox"],
  input[type="radio"] {
      &:checked {
          &+label {
              background: $background 0% 0% no-repeat padding-box !important;
              color: white !important;

              .header-label {
                  color: $textColor !important;
                  border-bottom: 1px solid #FFFFFF24;
              }

              .icon-element {
                  background-color: $textColor !important;
                  color: $background !important;
              }

              .content-label {
                  color: $textColor !important;
              }
          }
      }
  }

  .icon-element {
      background-color: $background !important;
  }

}


.action-element-card {



  &.view-xs {

      input[type="checkbox"],
      input[type="radio"] {
          &:checked {
              &+label {

                  color: white !important;
                  transform: scale(1) !important;
                  border-radius: 0;
                  box-shadow: none !important;

                  .header-label {
                      font-size: 1.2rem !important;
                      border-bottom: 1px solid #FFFFFF24;
                  }
              }
          }
      }

      label {
          margin-bottom: 0;
          border-radius: 0;
          box-shadow: none;
          min-height: 124px !important;

          &:first-child {
              border-top-left-radius: 20% !important;
          }

          .header-label {
              font-size: 1.2rem !important;
          }

          .icon-element {
              width: 35px !important;
              height: 35px !important;
              background-color: var(--gray-default);
              color: white;
              border-radius: 50%;
              line-height: 3.3rem !important;

              span {
                  font-size: 23px;
                  margin-top: 0 !important;
              }
          }
      }

      &:nth-child(1) {
          label {
              border-top-left-radius: 20% !important;
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
          }
      }

      &:nth-child(2) {
          label {
              border-top-right-radius: 20% !important;
              border-top-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
          }
      }

      &:nth-child(3) {
          label {
              border-bottom-left-radius: 20% !important;
              border-bottom-right-radius: 0 !important;
              border-top-right-radius: 0 !important;
              border-top-left-radius: 0 !important;
          }
      }

      &:nth-child(4) {
          label {
              border-bottom-right-radius: 20% !important;
              border-bottom-left-radius: 0 !important;
              border-top-right-radius: 0 !important;
              border-top-left-radius: 0 !important;
          }
      }

  }

  &.collection {
      @include customActionElementActiveCard(var(--red-medium), white);
  }

  &.processOfSale {
      @include customActionElementActiveCard(var(--green-medium), white);
  }

  &.invoicesCollected {
      @include customActionElementActiveCard(var(--blue-medium), white);
  }

  &.leaderboard {
      @include customActionElementActiveCard(var(--yellow-medium), white);
  }
}

table {

  &.table-two,
  &.table-graphics {
      width: 100%;
      box-shadow: 0px 1px 3px #0000003D;

      th {
          background-color: #FFFFFF;
          color: #274156;
          font-size: 14px;

          &.header-center {
              text-align: center !important;
          }

          &.mat-header-cell {
              border-bottom-style: none;
          }
      }

      td {

          &.mat-cell,
          &.mat-footer-cell {
              border-bottom-style: none;
              padding-top: 1.1rem !important;
              padding-bottom: 1.1rem !important;
          }
      }

      .mat-row {

          &:nth-child(even) {
              background-color: transparent;
              padding-top: 1.1rem !important;
              padding-bottom: 1.1rem !important;
          }

          &:hover {
              background-color: #00000008;
              cursor: default;
          }

      }

      .mat-badge-content {
          background-color: #01909B;
      }

      .mat-checkbox-checked {
          &.mat-accent {
              .mat-checkbox-ripple .mat-ripple-element {
                  opacity: 0.03 !important;
                  background-color: #01909B !important;
              }

              .mat-checkbox-background {
                  background-color: #01909B;
              }

          }
      }

      .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
          background-color: #01909B;
      }

  }

  &.table-graphics {
      box-shadow: none;
  }

  /* Centrar los header de las tablas */
  .header-center {

      .mat-sort-header-container,
      .mat-header-cell {
          display: flex;
          justify-content: center;
      }
  }
}

.table-responsive{
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-bottom: 0 !important;
}
.loading-layout {
  min-height: 400px;
  .locked {
      background-color: transparent !important;
  }
}
