.search-content {
  width: 203px;
  display: flex;
  background-color: white;
  border-radius: 3.9px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .14);
  max-width: 350px;
  width: 100%;
  height: 44px;
  overflow: hidden;
  padding: 0 0 0 10px;
  color: #626b72;
  margin-top: 19px;

  &:focus-within {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, .14) !important;
    border: 1px solid rgba(20, 179, 184, .5) !important;

    .search-img-2 {
      width: 26px;
      height: 30px;
    }
  }

  .search-input {
    margin-right: 10px;
    width: 100%;
    background-color: transparent !important;
    border: none !important;
    height: 43px !important;
  }

  .search-img-2 {
    margin: 5px 15px 0 15px;
    width: 26px;
    height: 30px;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}


.btn-group-kyc {
  &.mat-button-toggle-group {
    border-radius: 0px;
    border: none !important;

    .mat-button-toggle-appearance-standard {
      color: rgba(0, 0, 0, .87);
      background: white;
      border: 1px solid #274156;
      border-radius: 6px;

      .mat-button-toggle-button,
      .mat-button-toggle-label-content {
        height: 44px;

        &:focus {
          outline: none !important;
        }
      }

    }

    .mat-button-toggle {
      margin-right: 3rem;
      margin-left: 3rem;
      width: 200px;

      .mat-button-toggle-focus-overlay {
        // opacity: .04;
        opacity: 0 !important;
      }
    }

    .mat-button-toggle-checked {
      background: #19D7DB;
      color: white;
      border: 1px solid #19D7DB;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.btn-kyc {
  &.mat-button {
    font-family: 'Montserrat', sans-serif;
    color: white;
    min-width: 240px;
    height: 40px;
    background: transparent linear-gradient(180deg, #076D98 0%, #17AFAF 0%, #19D7DB 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    letter-spacing: 0.43px;
    font-size: 17px;

    &:disabled {
      border: 1px solid #274156;
      background: #E9E9E9 0% 0% no-repeat padding-box;
      color: #274156;
      opacity: .7;
    }
  }
}

.btn-custom-dialog {
  &.mat-button {
    color: white;
    min-width: 240px;
    height: 40px;
    background-color: #53acd2;
    border-radius: 5px;
    opacity: 1;
  }
}

.btn-kyc-icon {
  &.mat-icon-button {
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    line-height: 20px;
    background: transparent linear-gradient(180deg, #076D98 0%, #17AFAF 0%, #19D7DB 100%) 0% 0% no-repeat padding-box;

    &:disabled {
      border: 1px solid #274156;
      background: #274156 0% 0% no-repeat padding-box;
      color: #E9E9E9;
      opacity: .7;
    }
  }
}

.form-input-kyc,
.form-select-kyc,
.form-datepicker-kyc {
  width: 100%;

  .mat-label {
    display: flex !important;
    color: #19D7DB;
    font-weight: 600;
    padding-bottom: 1rem;
    width: 100%;
  }

  .mat-form-field-appearance-outline {
    width: 100%;

    .mat-form-field-wrapper {
      .mat-form-field-flex {

        .mat-form-field-outline {
          background: #F0F0F0 0% 0% no-repeat padding-box;
          opacity: 1;

          &:hover {
            opacity: 1;
            background: #F0F0F0 0% 0% no-repeat padding-box;
          }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
          border-color: #19D7DB80;
        }
      }

      .mat-form-field-subscript-wrapper {
        .mat-hint {
          color: red !important;
        }
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-prefix,
    .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: .5em;
    }
  }

}

.form-select-kyc {

  .mat-select-arrow {
    color: #19D7DB80 !important;

    &:active,
    &:focus,
    &::selection {
      color: #19D7DB80;
    }
  }
}


.form-radio-kyc {
  .mat-radio-button~.mat-radio-button {
    margin-left: 16px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #19D7DB;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #19D7DB;
  }

  .mat-radio-ripple .mat-ripple-element,
  .mat-radio-inner-circle {
    background-color: #19D7DB !important;
  }

  .mat-radio-checked {
    .mat-radio-label {
      border-radius: 19px;
      background-color: #19d8db1c;
    }
  }

  .mat-radio-label {
    padding: .8rem;
  }


}

.form-checkbox-kyc {
  &.mat-checkbox {
    margin: 0 12px;

    &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: #19D7DB !important;
      ;
    }

    .mat-checkbox-label {
      color: #19D7DB !important;
      ;
    }

    .mat-ripple-element {
      background-color: #19D7DB !important;
    }

    .mat-checkbox-checkmark {
      fill: #19D7DB !important;
    }


  }

}

.investment-amount {
  &.app-card-radio-kyc {
    label {
      p {
        text-align: center !important;
        font-weight: 700 !important;
        margin-top: 1.5rem !important;
      }
    }
  }
}

.input-select-custom {
  box-shadow: 0 2px 2px rgba(0, 0, 0, .14);

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #008F9A33;

  display: block;
  font-family: 'Monserrat', sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 4.6rem;
  padding-left: 5px;
  width: calc(100% + 10px);
  box-sizing: border-box;
  margin: 0;
  border-radius: 6px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('./../img/inputs-resource/down-arrow.svg'); //,linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  outline: none;


  &:focus {
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    opacity: .5;
  }

  &.disabled {
    // background-image: rgb(202, 199, 199);
    opacity: .5;
  }

  option {
    font-weight: normal;
  }
}

.custom-check {
  display: block !important;
  margin: .5rem;

  .mat-checkbox-layout {
    width: 100% !important;
    // border: 1px solid black;
    padding: 1rem 2rem;
    box-shadow: 0px 0px 3px #0000005C;
    border-radius: 6px;

    &>.mat-checkbox-inner-container {
      margin-top: auto !important;
      margin-bottom: auto !important;
      margin-right: 15px !important;
      margin-left: 0;

    }
    .mat-checkbox-label {
      color: #162D3D;
      font-weight: 500;
    }
  }
}
