/* You can add global styles to this file, and also import other style files */
/*@import '~font-awesome/css/font-awesome.css';*/
@import './assets/scss/main.scss';
@import './assets/scss/inputs.scss';

@font-face {
  font-family: 'Roboto', sans-serif;
  font-display: auto;
  src: local('Roboto') url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: auto;
  src: local('Montserrat') url('https://fonts.googleapis.com/css?family=Montserrat');
}


body {
  margin: 0;
}

.hover-row:hover {
  /* transform: translate(0, -4px); */
  background-color: rgba(146, 146, 142, 0.199);
  cursor: pointer;
  // border-top: 3px solid #5d6df854;
  // border-bottom: 3px solid #5d6df854;
  /* transform: scale(1.01); */
}

.hover-row {
  transition: transform .4s;
}

.form-control {
  height: 45px !important;
}

.modalbox-offers-d>mat-dialog-container {
  padding: 0 0 30px 0;
}

.title-card-d>.mat-card-header-text {
  width: 100% !important;
}

.margin-detail-o {
  margin-top: 3% !important;
}

.text-center {
  text-align: center;
}

.container-layout-reset .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #FFFFFF !important;
}

.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: #183245 !important;
}

app-get-questionnaire{
  display: flex;
  justify-content: center;
  // section{
  //     display: flex;
  //     justify-content: center;
  // }
}

app-question{
  width: 100%;

  .dropdown-menu{
      font-size: 14px!important;
  }
}


.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #274156 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
  border-color: #274156 !important;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #274156 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #274156 !important;
}

/*chaange color primary checkbox*/
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #274156!important;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: #274156 !important;
}
